* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Lato", sans-serif;
}

body {
  padding: 40px;
}

.top-div {
  margin: 15px 0;
  /* display: inline; */
}
.ui.dropdown {
  max-width: 800px;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(98, 81, 81);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: red;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: red;
}
/* .multi-select .options {
  height: fit-content !important;
} */
#chooseus {
  display: inline-flex;
}

#chooseus #city {
  width: 90px;
  height: 80px;
  border: #ffc107 3px solid;
  padding: 10px 0;
}
#cckk {
  float: unset;
  /* width: 202px; */
  /* margin: 25px  auto; */
  box-shadow: 0 7px 6px -6px #777;
  /* border: 3px solid rgb(5 239 5); */
}
#ckck {
  float: unset;
  width: 230px;
  margin: 15px 10px 25px 0;
  box-shadow: 0 7px 6px -6px #777;
  border: 3px solid rgb(5 239 5);
}
#ckck:hover {
  background-color: yellow;
  color: #000000;
  border: 3px solid rgb(255, 255, 147);
}
#kckc {
  margin: 0 10px 0 0;
  text-decoration: none;
  box-shadow: 0 7px 6px -6px #777;
  background: rgb(196 249 198);
  border: 3px solid rgb(165 245 180 / 98%);
  padding: 12px 30px;
}

#whatsicon {
  font-size: 45px;
  color: #25d366;
  font-weight: bold;
}
.result_head {
  display: flex;
  justify-content: space-between;
}
#xob {
  display: none;
}
.total_footer {
  display: flex;
  justify-content: space-between;
}

::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000000;

  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ffe600;
}

.herobtn {
  font-family: arial;
  color: #14396a !important;
  font-size: 18px;
  text-shadow: 1px 1px 0px #7cacde;
  box-shadow: 1px 1px 1px #bee2f9;
  padding: 20px 50px;
  border-radius: 10px;
  border: 2px solid #3866a3;
  background: #63b8ee;
  background: linear-gradient(to top, #63b8ee, #468ccf);
}

.freetransport {
  width: 24vw;
  text-align: right;
  margin-left: 0.5rem;
}

.paidtransport {
  width: 24vw;
  text-align: right;
  margin-left: 8.2rem;
}

.outerTransport {
  display: inline;
  /* border-bottom: 2px solid #ccc;
  box-shadow: 4px 4px 6px #ccc; */
  padding: 0.5rem ;
  font-size: 0.8rem;
}

.herobtn:hover {
  color: #14396a !important;
  background: #468ccf;
  background: linear-gradient(to top, #468ccf, #63b8ee);
}

table,
th,
td {
  /* border: 1px solid black; */
  border-collapse: collapse;
}

.multi-select .options {
  height: 150px;
  overflow-y: scroll;
}

.price_td {
  text-align: end;
  padding-right: 5px;
}
/* .show {
  display: inline-block;
} */
.hide {
  display: none;
}
.loader {
  border: 12px solid #f3f3f3;
  border-radius: 50%;
  border-top: 12px solid pink;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
table {
  width: 100%;
}

th:nth-child(2) {
  width: 10%;
}
.modal-backdrop.show {
  height: -webkit-fill-available;
  background-color: darkred;
  width: -webkit-fill-available;
  opacity: 1;
}
.modal-title {
  width: 800px;
  text-align: center;
}
.modal-header {
  padding: 5px 20px;
  border-bottom: none;
}
.modal-body {
  padding: 0 2rem;
}
.modal-60w {
  max-width: 60% !important;
}
.modal-content {
  border: none;
  background-color: #00000000;
}
#modal {
  background-color: #ffffff;
  border-radius: 25px;
  padding: 25px 0;
}
#boder {
  border: 3px solid #ffffff;
  position: absolute;
  top: 0;
  left: 0vw;
  right: 0vw;
  bottom: 0;
  transform: rotate(1deg);
  border-radius: 25px;
  z-index: -2;
}
.btn {
  margin: 3px !important;
}
.in {
  padding: 0 10px 0 0;
  width: 215px;
}
.dd {
  display: inline-block;
}
.dd .bnbn {
  width: 220px;
}
.ddo {
  display: inline-flex;
}
.bnbn {
  border: 3px solid #f6f3f39c;
  padding: 10px 0;
  /* margin-right: 10px; */
  border-radius: 10px;
  width: 165px;
  text-align: center;
  font-weight: 400;
}
#ocnc {
  border: 3px solid #f6f3f39c;
  padding: 20px 10px;
  color: black;
  font-size: 14px;
  /* margin-right: 25px; */
  border-radius: 10px;
  width: 100px;
  text-align: center;
  font-weight: 400;
  margin: 0 40px 0 0;
  box-shadow: 0 7px 6px -6px #777;
  height: 100px;
}
#ocnc:hover {
  background-color: #ffe600;
  border: none;
  padding: 20px 13px;
}
#check {
  /* float: right; */
  background-color: yellow;
  width: fit-content;
  border-radius: 10px;
  padding: 10px 12px;
  font-size: 15px;
  margin: 0;
  font-size: bold;
}
#check:hover {
  background-color: #ffc107;
}
#whatP {
  background-color: rgb(255, 255, 255);
  width: auto;
  height: auto;
  margin: 20px;
  position: fixed;
  bottom: 12px;
  color: rgb(0, 0, 0);
  border: #000000 2px solid;
  border-radius: 50px;
  text-align: left;
  padding: 10px 15px;
  display: inline-flex;
  align-items: center;
  font-size: 13px;
  left: 0;
}
#whatP:hover {
  background-color: rgb(255, 247, 19);
}
#whatP #whatsicon:hover {
  color: white;
}
#whatP #whatsicon {
  font-size: 30px;
  /* margin: 0 5px; */
}
#whatP p {
  width: 75px;
}
#sentM {
  display: none;
  padding: 25px;
  border-radius: 10px;
  width: fit-content;
  background-color: yellow;
  animation-name: example;
  animation-duration: 2s;
  animation-iteration-count: 3;
  margin: 20px auto;
}
#showmsg {
  padding: 25px;
  border-radius: 10px;
  width: fit-content;
  background-color: yellow;
  animation-name: example;
  animation-duration: 2s;
  animation-iteration-count: 3;
  margin: 20px auto;
}
@keyframes example {
  from {
    background-color: yellow;
  }
  to {
    background-color: lime;
  }
}
#web-h5 {
  font-size: 30px;
}
#occ {
  border: 3px solid #f6f3f39c;
  padding: 20px 10px;
  color: black;

  /* margin-right: 25px; */
  border-radius: 10px;
  width: 90px;
  text-align: center;
  font-weight: 400;
  margin: 0 12px 0 0;
  box-shadow: 0 7px 6px -6px #777;
  height: 90px;
}
#occ:hover {
  background-color: #ffe600;
  border: none;
  padding: 20px 10px;
}
#checkPrize {
  /* display: block; */
  text-align: center;
}
#ocn {
  border: 3px solid #f6f3f39c;
  color: black;
  padding: 7px 0;
  /* margin-right: 25px; */
  border-radius: 10px;
  width: 125px;
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  margin: 0 5px 0 0;
  -webkit-box-shadow: 0 10px 6px -6px #777;
  -moz-box-shadow: 0 10px 6px -6px #777;
  box-shadow: 0 7px 6px -6px #777;
}

#ocn:hover {
  background-color: #ffe600;
  border: none;
}
#city {
  border: 1px solid #f6f3f39c;
  color: black;
  padding: 0;
  font-size: 14px;
  /* margin-right: 25px; */
  border-radius: 10px;
  width: 100px;
  text-align: center;
  font-weight: 400;
  margin: 0 25px 0 0;
  height: 100px;
  -webkit-box-shadow: 0 10px 6px -6px #777;
  -moz-box-shadow: 0 10px 6px -6px #777;
  box-shadow: 0 7px 6px -6px #777;
}
#city:hover {
  background-color: #ffe600;
  border: none;
}
#city-logo {
  width: 100px;
  height: 100px;
}
.fa,
.fas {
  font-size: 24px;
  color: darkred;
  padding: 0 10px;
}
/* .svg-inline--fa.fa-w-14 {
  width: 30px;
}
.svg-inline--fa.fa-w-18 {
  width: 30px;
}
.svg-inline--fa.fa-w-6 {
  width: auto;
}
.svg-inline--fa.fa-w-8 {
  width: auto;
}
.svg-inline--fa.fa-w-16 {
  width: 30px;
}
.svg-inline--fa {
  height: 30px;
} */
#tddt {
  text-align: right;
}
.ni {
  display: inline-flex;
  align-self: center;
}
/* add class with switch like in landing page one veg veg */
.nonveg {
  background-color: lime;
  width: 105px;
  margin: 5px 17px;
}

.nn {
  width: 100%;
  padding: 5px;
  border-radius: 10px;
  border-width: 3.1px;
  border-color: black;
  font-size: 14px;
}
.di {
  display: inline-grid;
  /* width: 45%; */
  padding: 0 10px 0 0;
}
.id {
  align-self: center;
}
.di .id {
  margin-right: 10px;
  font-size: 17px;
}
.form-select {
  border-radius: 10px;
  font-size: 14px;
  border: 3px solid #f6f3f39c;
  width: 200px;
  -webkit-box-shadow: 0 10px 6px -6px #777;
  -moz-box-shadow: 0 10px 6px -6px #777;
  box-shadow: 0 7px 6px -6px #777;
}
.form-control {
  font-size: 14px;
}
.date {
  -webkit-box-shadow: 0 10px 6px -6px #777;
  -moz-box-shadow: 0 10px 6px -6px #777;
  box-shadow: 0 7px 6px -6px #777;
}
.caterNinja_add_dessert_button {
  color: #000000;
  cursor: pointer;
  /* text-decoration: overline; */
  display: inline-block;
  background-color: orange;
  padding: 5px 15px;
  border-radius: 10px;
  margin-top: 5px;
}
.caterNinja_add_dessert_button_hide {
  display: none;
}
#tavel {
  width: 100%;
  margin: 15px 0 40px 0;
  border: none;
}

/* .multi-select .selected-options {
  vertical-align: middle;
  height: inherit;
  border: 1px solid #ccc;
  border-radius: 36px;
  display: inline-block;
  padding: 10px 0;
  width: inherit;
} */
#chat {
  background-color: white;
}
hr {
  border: 7px dotted #ffe600;
  border-style: none none dotted;
  /* color: #b04626; */
  background-color: #fff;
  width: 75%;
  margin: 15px auto;
  opacity: 1;
}
.btn {
  border-radius: 10px;
}
input {
  border-radius: 10px;
  border-width: thin;
}
#gpay {
  margin: -5px auto;
  float: right;
}
.bn {
  padding: 10px 40px;
  background-color: rgb(3, 255, 3);
  color: black;
  border: none;
  border-radius: 10px;
  float: unset;
}
input[type="date"] {
  border-radius: 10px;
  border: 3px solid #f6f3f39c;
}
#cicc {
  align-self: center;
  font-size: 17px;
}

.nb {
  float: right;
  padding: 10px 40px;
  background-color: rgb(3, 150, 255);
  color: black;
  border-radius: 10px;
  border: none;
}
#fontR {
  width: 28.5vw;
  font-size: 20px;
}
#Iwant {
  display: inline-flex;
}
#want {
  align-self: center;
}
#deet {
  width: 250px;
}
input:focus {
  outline: none;
}
#onno {
  align-self: center;
  width: 100px;
  font-size: 17px;
}
#ppl {
  font-size: 17px;
}

#det {
  padding: 12px;
}
#your {
  align-self: center;
  font-size: 17px;
}
#vv {
  padding-right: 45px;
}
#vv .top-div {
  margin: 0;
}
#pref {
  align-self: center;
  font-size: 17px;
}
#cuz {
  width: 85px;
  align-self: center;
}
#duck {
  display: inline-flex;
}
@media only screen and (min-width: 576px) {
  .modal-dialog {
    max-width: 875px;
  }
}
@media only screen and (max-width: 767px) {
  .ui.selection.dropdown .menu {
    /*      max-height: 8.01428571rem; /* + 1.335714285 to 9.349999995rem */
    /*      max-height: 9.349999995rem; /* Adds a half */
    max-height: 16.02857142rem; /* Double size */
  }
}
@media only screen and (min-width: 768px) {
  .ui.selection.dropdown .menu {
    /*         max-height: 10.68571429rem; /* + 1.3357142863 to 12.0214285763rem */
    max-height: 12.0214285763rem;
  }
}
@media only screen and (min-width: 992px) {
  .ui.selection.dropdown .menu {
    max-height: 16.02857143rem; /* + 1.3357142858 to 17.3642857158rem */
  }
}
@media only screen and (min-width: 1920px) {
  .ui.selection.dropdown .menu {
    max-height: 21.37142857rem; /* + 1.3357142856 to 22.7071428556rem */
  }
}
@media only screen and (max-width: 800px) {
  .logo {
    width: 75%;
  }
  #mmm {
    display: none;
    visibility: hidden;
  }
  #whatP {
    margin: 0;
    padding: 5px 0;
    background-color: #25d366;
    border: none;
  }
  #ckck {
    margin: 25px auto;
  }
  #kckc {
    margin: 0;
  }
  #whatP #whatsicon {
    background-color: none;
    margin: 5px;
  }
  #whatP #whatsicon:hover {
    background-color: rgb(255, 247, 19);
  }
  .topDiv {
    font-size: 12px;
  }
  .ulol {
    padding: 0;
  }
  #city {
    width: 105px;
    margin: 0 20px 15px 0;
  }
  #cicc {
    width: 75px;
  }
  .bn {
    border-radius: 5px;
    float: unset;
    width: -webkit-fill-available;
    margin: 0 10px 0 0;
    padding: 10px;
  }
  #gpay {
    float: unset;
    margin: 0 auto;
  }

  #ocnc {
    padding: 12px 10px;
    border-radius: 10px;
    width: 155px;

    margin: 10px 5px;
    height: auto;
  }
  #ocnc:hover {
    padding: 12px 10px;
  }
  #occ:hover {
    padding: 12px 10px;
  }
  #occ {
    padding: 12px 10px;
    border-radius: 10px;
    width: 155px;

    margin: 10px 5px;
    height: auto;
  }
  .id {
    align-self: unset;
  }
  #vv {
    padding-right: 5px;
  }
  .ddo {
    display: inline-block;
  }
  #ocn {
    padding: 10px 0;
    border-radius: 10px;
    width: 152px;

    margin: 0 5px;
    height: fit-content;
  }
  .modal-backdrop.show {
    height: -webkit-fill-available;
    background-color: darkred;
    width: -webkit-fill-available;
    opacity: 1;
  }
  .modal-dialog {
    margin: 60px 30px;
  }
  /* .form-select {
    height:auto;
  } */
  body {
    padding: 40px 0;
    box-sizing: border-box;
    width: fit-content;
  }
  #duck {
    display: inline;
  }
  #fontR {
    width: 50vw;
    font-size: 12px;
  }
}

@media only screen and (max-width: 500px) {
  .modal-body {
    padding: 0 1rem;
  }
  #cicc {
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
  }
  #city-div {
    text-align: center;
  }
  #city {
    width: 16vw;
    height: 17vw;
    margin: 0 5px 5px 0;
    font-size: 14px;
    font-weight: 500;
    /* padding: 5px; */
  }
  #city-logo {
    width: 16vw;
    height: 16vw;
  }
  #web-h5 {
    font-size: 19px;
    margin: 0;
  }
  .fa,
  .fas {
    font-size: 19px;
  }
  #xob {
    width: 70vw;
    display: initial;
    border-radius: 10px;
    height: 25vw;
  }
  #h6h6 {
    font-size: 12px;
  }
  /* .svg-inline--fa.fa-w-14 {
    width: 25px;
  }
  .svg-inline--fa.fa-w-18 {
    width: 25px;
  }
  .svg-inline--fa.fa-w-6 {
    width: auto;
  }
  .svg-inline--fa.fa-w-8 {
    width: auto;
  }
  .svg-inline--fa.fa-w-16 {
    width: 25px;
  }
  .svg-inline--fa {
    height: 25px;
  } */
  /* #checkPrize{
    display: inline-flex;
  } */
  .di .id {
    font-size: 14px;
    margin-right: 5px;
  }
  #div-top #dot {
    display: inline-flex;
  }

  .ni {
    display: inline;
  }
  #duck {
    display: inline;
  }
  #Iwant {
    display: block;
    text-align: center;
  }
  #ocnc {
    padding: 5px;
    width: 35vw;
    margin: 5px 0;
    font-size: 12px;
    font-weight: 500;
    border-radius: 10px;
  }
  #ocnc:hover {
    padding: 5px;
  }
  #onno {
    align-self: center;
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    text-transform: uppercase;
    text-align: center;
  }
  #pref {
    /* width: 85px; */
    font-size: 14px;
    font-weight: bold;
  }
  #ocn {
    padding: 5px 0;
    width: 32vw;
    margin: 5px 0;
    font-size: 12px;
    font-weight: 500;
    margin: 0 5px 10px 0;
  }
  #ppl {
    font-size: 14px;
    width: 126px;
    margin: 0;
    align-self: center;
  }
  #div-top .di #ocn {
    /* background-color: red;   */
    width: 90px;
  }
  .top-div {
    margin: 15px 0 30px 0;
  }

  input[type="date"] {
    width: 155px;
  }
  .form-select {
    width: 155px;
  }
  #vv {
    margin-bottom: 20px;
    display: inline-flex;
  }
  #vv .top-div {
    display: inline-flex;
  }
  #need {
    display: inline-block;
    /* width: -webkit-fill-available; */
  }
  .id {
    align-self: center;
    font-size: 12px;
    font-weight: bold;
    width: auto;
  }
  #det {
    padding: 5px 15px;
  }
  #dot .id {
    width: 120px;
  }
  #occ {
    font-size: 12px;
    font-weight: 500;
    width: 115px;
    padding: 5px;
    margin: 5px;
  }
  #occ:hover {
    padding: 5px 10px;
  }
  #cuz {
    font-size: 14px;
    font-weight: bold;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
  }
  #want {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 5px;
  }
  .ddo #ocnc {
    display: inline-flex;
  }
  .fa,
  .fas {
    font-size: 15px;
    padding: 2px 5px;
  }
  #your {
    align-self: center;
    font-size: 14px;
    font-weight: bold;
    width: 105px;
  }
  .in {
    padding: 0 2px;
    margin: 5px 0;
    width: auto;
  }
  #tdtd {
    padding: 0 30px 0 0;
    width: 180px;
  }
  #tavel {
    width: 100%;
  }
  #cp {
    font-size: 20px;
  }
  #deet {
    width: 155px;
  }
  #tddt {
    text-align: right;
  }
  #chooseus {
    display: inline-flex;
  }
  #chooseus #city {
    width: 90px;
    border: #ffc107 3px solid;
    padding: 10px 0;
  }
  #whatsicon {
    font-size: 45px;
    color: #ffffff;
    font-weight: bold;
  }

  .outerTransport {
    display: inline;
    /*border-bottom: 2px solid #ccc;
    box-shadow: 4px 4px 6px #ccc;*/
    padding: 0.2rem ;
    font-size: 0.7rem;
  }

  .freetransport {
    margin-right: -0.6rem;
  }

  .paidtransport {
    margin-left: 6.5rem;
  }
}

@media only screen and (max-width: 375px) {
  .outerTransport {
    display: inline;
    /*border-bottom: 2px solid #ccc;
    box-shadow: 4px 4px 6px #ccc;*/
    padding: 0.2rem ;
    font-size: 0.7rem;
  }

  .freetransport {
    margin-right: -0.2rem;
  }

  .paidtransport {
    margin-left: 3.1rem;
  }

  #city {
    width: 60px;
    height: 60px;
  }
  #city-logo {
    width: 45px;
    height: 55px;
  }
  .top-div {
    text-decoration: none;
    padding-left: 0;
    font-size: 12px;
  }

  #ocnc {
    font-size: 10px;
    width: 33vw;
  }
  .id {
    width: 80px;
  }
  #deet {
    width: 155px;
  }
  .ni {
    display: inline;
  }
  /* #dot {
    display: inline-flex;
} */
  #dot .id {
    width: 90px;
  }
  #ppl {
    width: 90px;
  }

  #vv {
    margin-bottom: 12px;
  }
  /* .modal-title, .h4 {
    width: 800px;
    text-align: center;
    font-size: 20px;
    text-align: center;
  } */

  #occ {
    width: 31vw;
    padding: 4px 0;
  }
  #ocn {
    width: 70px;
  }
  .top-div,
  ol,
  ul {
    font-size: 12px;
    padding-left: 0;
  }
  .fa,
  .fas {
    font-size: 15px;
    padding: 0 5px;
  }
  #gndT {
    font-size: 16px;
    text-align: right;
  }
}
